import { useEffect, useState } from "react"
import { LOG } from "../../config"
import { LocalProduct } from "../../storage/types"
import { TextBox } from "../../ui"
import { CardSlider, InnerCard, InnerCardVertical } from "./CardSlider"

const log = LOG.extend('Views')

type ProductListProps = {
    products: LocalProduct[],
    sponsoredProducts?: LocalProduct[],
    emptyProductsLabel?: string,
    name: string,
    hideSearch?: boolean
}

export const ProductList = (props: ProductListProps) => {
    log.debug('ProductList')
    const [products, setProducts] = useState<LocalProduct[]>([])
    const [searchText, setSearchText] = useState('')

    useEffect(()=> {
        log.debug('ProductList Effect on props.products')
        if (searchText === "") {
            log.debug('serchTest is empty')
            setProducts(props.products)
        } else {
            log.debug('serchTest is not empty, filtering products')
            const filteredProducts = props.products.filter((product) => product.name.toLocaleLowerCase().includes(searchText.toLowerCase()))
            setProducts(filteredProducts)
        }
    },props.products)

    log.debug(`Mapping all the products cards`)
    const Cards = products.map((product, index) => <div key={index} className="bg-white h-28 mx-2 my-2 shadow-2xl rounded-lg space-y-2"><InnerCard product={product}/></div>)
    
    const noProducts = <div className="flex text-center p-10 h-28">{(props.emptyProductsLabel && props.emptyProductsLabel !== "")? props.emptyProductsLabel: "Nessun prodotto attualmente disponibile in questa categoria"}</div>

    const onSearch = (text: string) => {
        setSearchText(text)
        if (text === "") {
            log.debug(`Search text empty`)
            setProducts(props.products)
        } else {
            log.debug(`Searching: ${text}`)
            log.debug(`Filtering products`)
            const filteredProducts = props.products.filter((product) => product.name.toLocaleLowerCase().includes(text.toLowerCase()))
            setProducts(filteredProducts)
        }
    }

    return <div id={props.name} className="flex flex-col">
        {
            (!props.hideSearch)? <div className="p-2 fixed w-full z-50">
                <TextBox className="h-12" value={searchText} onChange={(event) => onSearch(event.target.value)} id='search' type="text" required={false} placeholder="Cerca..."/>
            </div>: null
        }
        <div className={(!props.hideSearch)? 'mt-14' : ''}>
            {(Cards.length > 0)? Cards : noProducts}
        </div>
        {/* {(Cards.length < 2)? <div className="h-[calc(100%_-_28rem)]"></div>: null}
        {(Cards.length === 2)? <div className="h-[calc(100%_-_35rem)]"></div>: null} */}
        
        {(props.sponsoredProducts && props.sponsoredProducts.length > 0)? <div className="mb-4">
            <label className="text-center pl-2">Altri prodotti suggeriti</label>
            <CardSlider products={props.sponsoredProducts}/>
            {(Cards.length < 3)? null : <div className="h-28"></div>}
            </div>: <div className="h-28"></div> }
    </div>
}


export const ProductListDesktop = (props: ProductListProps) => {
    log.debug('ProductList')
    const [products, setProducts] = useState<LocalProduct[]>([])
    const [searchText, setSearchText] = useState('')

    useEffect(()=> {
        log.debug('ProductList Effect on props.products')
        if (searchText === "") {
            log.debug('serchTest is empty')
            setProducts(props.products)
        } else {
            log.debug('serchTest is not empty, filtering products')
            const filteredProducts = props.products.filter((product) => product.name.toLocaleLowerCase().includes(searchText.toLowerCase()))
            setProducts(filteredProducts)
        }
    },props.products)

    log.debug(`Mapping all the products cards`)
    const Cards = products.map((product, index) => <InnerCardVertical product={product}/>)
    
    const noProducts = <div className="flex text-center p-10 h-28">{(props.emptyProductsLabel && props.emptyProductsLabel !== "")? props.emptyProductsLabel: "Nessun prodotto attualmente disponibile in questa categoria"}</div>

    const onSearch = (text: string) => {
        setSearchText(text)
        if (text === "") {
            log.debug(`Search text empty`)
            setProducts(props.products)
        } else {
            log.debug(`Searching: ${text}`)
            log.debug(`Filtering products`)
            const filteredProducts = props.products.filter((product) => product.name.toLocaleLowerCase().includes(text.toLowerCase()))
            setProducts(filteredProducts)
        }
    }

    return <div id={props.name} className="flex flex-col xl:max-w-screen-xl m-auto mb-14 sm:mb-36">
        {
            (!props.hideSearch)? <div className="p-2 w-full">
                <TextBox className="h-8" value={searchText} onChange={(event) => onSearch(event.target.value)} id='search' type="text" required={false} placeholder="Cerca..."/>
            </div>: null
        }
        <div className={(!props.hideSearch)? '' : ''}>
            <div className="sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-2 ">
                {(Cards.length > 0)? Cards : noProducts}
            </div>
        </div>
        
        {/* {(props.sponsoredProducts && props.sponsoredProducts.length > 0)? <div className="mb-4">
            <label className="text-center pl-2">Altri prodotti suggeriti</label>
            <CardSlider products={props.sponsoredProducts}/>
            {(Cards.length < 3)? null : <div className="h-28"></div>}
            </div>: <div className="h-28"></div> } */}
    </div>
}